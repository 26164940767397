import { createSlice } from "@reduxjs/toolkit";

import { IStation } from "Api/V1/Models";

export interface IStationsState {
	canManageStations: boolean;
	tagName: string;
	page: number;
	pageCount: number;
	prevPage?: string;
	nextPage?: string;
	stations: IStation[];
}

declare const stationsState: IStationsState;

const blankState: IStationsState = {
	canManageStations: false,
	page: 1,
	pageCount: 1,
	stations: [],
	tagName: ""
};

export function createStationsSlice() {
	return createSlice({
		initialState: { ...blankState, ...(typeof stationsState !== "undefined" ? stationsState : {}) },
		name: "stations",
		reducers: {
		}
	});
}

export type StationsActionCreators = ReturnType<typeof createStationsSlice>["actions"];

export type StationsReducer = ReturnType<typeof createStationsSlice>["reducer"];
