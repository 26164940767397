﻿import { format } from "date-fns/format";
import { formatDistanceStrict } from "date-fns/formatDistanceStrict";

export function friendlyDate(dateTime: Date | string | undefined) {
	if (dateTime == null) {
		return "";
	}

	const date = typeof dateTime === "string" ? parseDateTime(dateTime) : dateTime;
	if (date == null) {
		return "";
	}

	const now = Date.now();
	const diff = (now - date.getTime()) / 1000;

	if (diff < 0 || diff > 60 * 60 * 24 * 7) {
		return format(date, "yyyy-MM-dd");
	}

	return formatDistanceStrict(date, now, { addSuffix: true });
}

function parseDateTime(dateString: string): Date | null {
	if (!dateString) {
		return null;
	}

	const matcher = /\/Date\((\d+)\)\//;
	const matched = matcher.exec(dateString);
	if (matched != null && matched.length > 1) {
		return new Date(parseInt(matched[1], 10));
	}

	return new Date(dateString);
}
