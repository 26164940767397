import { IStation } from "Api/V1/Models";

export function stationsUrl(tag?: string, page?: number): string {
	return "/stations/"
		+ (tag ? "withtag/" + urlSafe(tag) + "/" : "")
		+ (page != null ? page.toString() + "/" : "");
}

export function editStationUrl(station: IStation): string {
	return station.id != null ? "/stations/edit/" + id(station.id) + "/" + urlSafe(station.title) + "/" : "";
}

export function playlistUrl(station: IStation): string {
	return station.id != null ? "/stations/playlist/" + id(station.id) + "/" + urlSafe(station.title) + ".m3u" : "";
}

function urlSafe(part: string) {
	if (!part) {
		return "";
	}

	return part.replace(new RegExp("[^\\w\\.,_\\-\\(\\)\\[\\]]", "g"), "_");
}

function id(fullId: string) {
	return fullId ? fullId.substr(0, 9) : fullId;
}
