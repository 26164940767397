export interface ITopbarOptions {
	autoRun: boolean;
	barColors: [stop: number, color: string][];
	barThickness: number;
	container?: HTMLElement | null;
	className: string | null;
	shadowBlur: number;
	shadowColor: string;
}

const defaultOptions: ITopbarOptions = {
	autoRun: true,
	barColors: [
		[0, "rgba(26, 188, 156, 0.9)"],
		[0.25, "rgba(52, 152, 219, 0.9)"],
		[0.50, "rgba(241, 196, 15, 0.9)"],
		[0.75, "rgba(230, 126, 34, 0.9)"],
		[1, "rgba(211, 84, 0, 0.9)"]
	],
	barThickness: 3,
	className: null,
	shadowBlur: 10,
	shadowColor: "rgba(0, 0, 0, .6)"
};

function createCanvas(className: string | null, inline: boolean) {
	const canvas = document.createElement("canvas");
	const style = canvas.style;
	style.position = inline ? "relative" : "fixed";
	style.top = style.left = style.right = style.margin = style.padding = "0";
	style.zIndex = inline ? "" : "100001";
	style.display = "none";

	if (className) {
		canvas.classList.add(className);
	}

	return canvas;
}

export function createTopbar(opts: Partial<ITopbarOptions>) {
	const options = {
		...defaultOptions,
		...opts
	};

	const canvas = createCanvas(options.className, opts.container != null);
	let progressTimerId: number | null = null;
	let fadeTimerId: number | null = null;
	let currentProgress = 0;
	let showing = false;

	(opts.container ?? document.body).appendChild(canvas);
	window.addEventListener("resize", repaint);

	function repaint() {
		canvas.width = window.innerWidth;
		canvas.height = options.barThickness * 5; // need space for shadow

		const ctx = canvas.getContext("2d");
		if (!ctx) {
			return;
		}

		ctx.shadowBlur = options.shadowBlur;
		ctx.shadowColor = options.shadowColor;

		const lineGradient = ctx.createLinearGradient(0, 0, canvas.width, 0);

		for (const [stop, color] of options.barColors) {
			lineGradient.addColorStop(stop, color);
		}

		ctx.lineWidth = options.barThickness;
		ctx.beginPath();
		ctx.moveTo(0, options.barThickness / 2);
		ctx.lineTo(
			Math.ceil(currentProgress * canvas.width),
			options.barThickness / 2
		);
		ctx.strokeStyle = lineGradient;
		ctx.stroke();
	}

	function show() {
		if (showing) {
			return;
		}

		showing = true;

		if (fadeTimerId != null) {
			window.cancelAnimationFrame(fadeTimerId);
		}

		canvas.style.opacity = "1";
		canvas.style.display = "block";
		progress(0);

		function loop() {
			progressTimerId = window.requestAnimationFrame(loop);
			progress(
				"+" + (0.05 * Math.pow(1 - Math.sqrt(currentProgress), 2)).toString()
			);
		}

		if (options.autoRun) {
			loop();
		}
	}

	function progress(to?: number | string) {
		if (typeof to === "undefined") {
			return currentProgress;
		}

		if (typeof to === "string") {
			to = (to.includes("+") || to.includes("-") ? currentProgress : 0) + parseFloat(to);
		}

		currentProgress = to > 1 ? 1 : to;
		repaint();

		return currentProgress;
	}

	function hide() {
		if (!showing) {
			return;
		}

		showing = false;

		if (progressTimerId != null) {
			window.cancelAnimationFrame(progressTimerId);
			progressTimerId = null;
		}

		function loop() {
			if (progress("+.1") >= 1) {
				canvas.style.opacity = (parseFloat(canvas.style.opacity) - 0.05).toString();

				if (parseFloat(canvas.style.opacity) <= 0.05) {
					canvas.style.display = "none";
					fadeTimerId = null;

					return;
				}
			}

			fadeTimerId = window.requestAnimationFrame(loop);
		}

		loop();
	}

	return {
		hide,
		progress,
		show
	};
}
