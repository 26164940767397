import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { createContext, useContext } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { StationActionCreators, StationReducer, createStationSlice } from "Station/StationStore";
import { StationsActionCreators, StationsReducer, createStationsSlice } from "Stations/StationsStore";

import { SearchActionCreators, SearchReducer, createSearchSlice } from "./Search/SearchStore";

export interface IActionCreators {
	search: SearchActionCreators;
	station: StationActionCreators;
	stations: StationsActionCreators;
}

function createStore(reducer: { search: SearchReducer; station: StationReducer; stations: StationsReducer }) {
	return configureStore({
		reducer
	});
}

export type AppDispatch = ReturnType<typeof createStore>["dispatch"];

export type AppState = ReturnType<ReturnType<typeof createStore>["getState"]>;

export type AppThunk = ThunkAction<Promise<void>, AppState, undefined, Action>;

let actionContext: React.Context<IActionCreators> | null = null;

export function setupActions(actions: IActionCreators) {
	actionContext = createContext(actions);

	return actionContext.Provider;
}

export function useActions() {
	if (actionContext == null) {
		throw new Error("Action context not initialized");
	}

	return [useDispatch<AppDispatch>(), useContext(actionContext)] as const;
}

export const useStateSelector: TypedUseSelectorHook<AppState> = useSelector;

export function buildStore() {
	const searchSlice = createSearchSlice();
	const stationSlice = createStationSlice();
	const stationsSlice = createStationsSlice();

	const store = createStore({
		search: searchSlice.reducer,
		station: stationSlice.reducer,
		stations: stationsSlice.reducer
	});

	const actions: IActionCreators = {
		search: searchSlice.actions,
		station: stationSlice.actions,
		stations: stationsSlice.actions
	};

	const ActionProvider = setupActions(actions);

	return {
		ActionProvider,
		actions,
		store
	};
}
