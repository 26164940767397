import { IStation } from "Api/V1/Models";
import { progress } from "Services/Progress";

export const StationApi = {
	create(station: IStation) {
		return stationApiRequest("POST", "/api/v1/stations/create", station);
	},
	remove(station: IStation) {
		if (!station.id) {
			throw Error("Station ID is missing");
		}

		return stationApiRequest("DELETE", `/api/v1/stations/${station.id}`);
	},
	restore(station: IStation) {
		if (!station.id) {
			throw Error("Station ID is missing");
		}

		return stationApiRequest("POST", `/api/v1/stations/${station.id}/restore`);
	},
	update(station: IStation) {
		if (!station.id) {
			throw Error("Station ID is missing");
		}

		return stationApiRequest("POST", `/api/v1/stations/${station.id}/update`, station);
	}
};

function stationApiRequest(method: string, url: string, station?: IStation) {
	const headers = new Headers();
	headers.append("Accept", "application/json");
	headers.append("Content-Type", "application/json");
	headers.append("X-XSRF-TOKEN", getAntiForgeryToken());

	const options: RequestInit = {
		body: JSON.stringify(station),
		credentials: "include",
		headers,
		method
	};

	return progress(fetch(url, options));
}

function getAntiForgeryToken(): string {
	const token = document.querySelector<HTMLInputElement>("input[name=\"__RequestVerificationToken\"]")?.value;

	if (!token) {
		throw new Error("Did not find anti forgery token");
	}

	return token;
}
