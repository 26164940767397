import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import debounce from "debounce";

import { IStation } from "Api/V1/Models";
import { ISearchResponse } from "Api/V1/Search";

import type { AppDispatch, AppThunk, IActionCreators } from "../Store";

import { SearchApi } from "./SearchApi";

export interface ISearchState {
	query: string;
	searching: boolean;
	searchCompleted: boolean;
	searchFailed: boolean;
	showSearch: boolean;
	showSearchResults: boolean;
	stations: IStation[];
	tags: string[];
	withinactive: boolean;
}

declare const searchState: ISearchState;

const blankState: ISearchState = {
	query: "",
	searchCompleted: false,
	searchFailed: false,
	searching: false,
	showSearch: false,
	showSearchResults: false,
	stations: [],
	tags: [],
	withinactive: false
};

export function createSearchSlice() {
	/* eslint-disable sort-keys */
	return createSlice({
		initialState: { ...blankState, ...(typeof searchState !== "undefined" ? searchState : {}) },
		name: "search",
		reducers: {
			clearSearch: () => {
				return blankState;
			},
			searchTriggered: (state, { payload }: PayloadAction<{ query: string }>) => {
				state.query = payload.query;
			},
			searchStarted: state => {
				state.searching = true;
				state.showSearchResults = true;
			},
			searchFailed: (state, { payload }: PayloadAction<{ query: string }>) => {
				if (payload.query !== state.query) {
					return;
				}

				state.searchCompleted = false;
				state.searchFailed = true;
				state.searching = false;
				state.stations = [];
				state.tags = [];
			},
			searchCompleted: (state, { payload }: PayloadAction<{ query: string; response: ISearchResponse }>) => {
				if (payload.query !== state.query) {
					return;
				}

				state.searchCompleted = true;
				state.searchFailed = false;
				state.searching = false;
				state.stations = payload.response.stations;
				state.tags = payload.response.tags;
			}
		}
	});
	/* eslint-enable sort-keys */
}

export type SearchActionCreators = ReturnType<typeof createSearchSlice>["actions"];

export type SearchReducer = ReturnType<typeof createSearchSlice>["reducer"];

async function actualSearch(dispatch: AppDispatch, actions: IActionCreators, query: string, count: number, withinactive: boolean) {
	if (query.length < 2) {
		return;
	}

	dispatch(actions.search.searchStarted());

	try {
		dispatch(actions.search.searchCompleted({
			query,
			response: await SearchApi.search(query, count, withinactive)
		}));
	} catch {
		dispatch(actions.search.searchFailed({ query }));
	}
}

const throttledSearch = debounce(actualSearch, 500, false);

export function search(actions: IActionCreators, query: string, count: number, throttled = true): AppThunk {
	return async (dispatch, getState) => {
		if (query.length === 0) {
			dispatch(actions.search.clearSearch());

			return;
		}

		dispatch(actions.search.searchTriggered({ query }));

		const state = getState();
		const withinactive = state.search.withinactive === true;
		if (throttled) {
			await throttledSearch(dispatch, actions, query, count, withinactive);
		} else {
			await actualSearch(dispatch, actions, query, count, withinactive);
		}
	};
}
