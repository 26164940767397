import { ISearchResponse } from "Api/V1/Search";
import { progress } from "Services/Progress";

const cache: Record<string, ISearchResponse | undefined> = {};

interface IQueryParams {
	count: number;
	query: string;
	withinactive: boolean;
}

function getQueryString(params: Partial<IQueryParams>): string {
	const keys = Object.keys(params) as (keyof IQueryParams)[];

	return keys.map(key => `${key}=${params[key]?.toString() ?? ""}`).join("&");
}

export const SearchApi = {
	async search(query: string, count: number, withinactive: boolean): Promise<ISearchResponse> {
		const qs = getQueryString(withinactive ? { count, query, withinactive } : { count, query });
		const cachedResponse = cache[qs];
		if (cachedResponse) {
			return cachedResponse;
		}

		const headers = new Headers();
		headers.append("Accept", "application/json");

		const response = await progress(fetch("/api/v1/search/?" + qs, { credentials: "include", headers }));
		if (response.ok) {
			return cache[qs] = await response.json() as ISearchResponse;
		}

		throw new Error("Search API error");
	}
};
