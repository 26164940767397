import { memo, useState } from "react";
import { createRoot } from "react-dom/client";

interface INotification {
	id: number;
	className: string;
	iconClassName: string;
	message?: string | null;
	title: string;
}

const Notification = memo(function Notification(props: INotification & { onClose: () => void }) {
	return (
		<div className={"toast show text-white bg-" + props.className}>
			<div className="toast-header" style={{ borderWidth: 0 }}>
				<i className={`bi ${props.iconClassName} me-2`} style={{ paddingRight: "10px" }}></i>
				<strong className="me-auto">{props.title}</strong>
				<button className="btn-close" onClick={props.onClose}></button>
			</div>
		</div>
	);
});

let nextId = 0;

let addNotification: (className: string, iconClassName: string, timeout: number, title: string, message?: string | null) => void = () => {
	/* noop */
};

function NotificationContainer() {
	const [notifications, setNotifications] = useState<INotification[]>([]);

	function dismiss(id: number) {
		setNotifications(prev => prev.filter(notification => notification.id !== id));
	}

	addNotification = (className: string, iconClassName: string, timeout: number, title: string, message?: string | null) => {
		const id = nextId++;
		setNotifications(prev => [...prev, { className, iconClassName, id, message, title }]);
		setTimeout(() => dismiss(id), timeout);
	};

	return (
		<div className="notification-container">
			<div className="toast-container">
				{notifications.map(notification => (
					<Notification key={notification.id} {...notification} onClose={() => dismiss(notification.id)} />
				))}
			</div>
		</div>
	);
}

const notificationElement = document.getElementById("notifications");
if (notificationElement != null) {
	const root = createRoot(notificationElement);

	root.render(<NotificationContainer />);
}

export const Notify = {
	error(title: string, message?: string | null) {
		addNotification("danger", "bi-exclamation-circle-fill", 5_000, title, message);
	},
	success(title: string, message?: string | null) {
		addNotification("success", "bi-check-lg", 2_000, title, message);
	},
	warning(title: string, message?: string | null) {
		addNotification("warning", "bi-exclamation-triangle-fill", 3_000, title, message);
	}
};
