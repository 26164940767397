import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IStation } from "Api/V1/Models";
import { IStationResponse } from "Api/V1/Stations";
import { editStationUrl } from "Models/Urls";
import { Notify } from "Notifications/Notify";

import { AppThunk, IActionCreators } from "../Store";

import { StationApi } from "./StationApi";

export interface IStationState {
	availableTags: string[];
	dirty: boolean;
	operationFailed: boolean;
	operationInProgress: boolean;
	station: IStation;
}

declare const stationState: IStationState;

const blankStation: IStation = {
	endpoints: [],
	id: null,
	links: {
		application: "",
		page: "",
		website: ""
	},
	tags: [],
	title: ""
};

const blankState: IStationState = {
	availableTags: [],
	dirty: false,
	operationFailed: false,
	operationInProgress: false,
	station: blankStation
};

function addTag(station: IStation, tag: string | undefined): IStation {
	if (tag == null) {
		return station;
	}

	const lowercaseTag = tag.toLowerCase();
	if (station.tags.some(t => t.toLowerCase() === lowercaseTag)) {
		return station;
	}

	const tags = [...station.tags, tag];

	return { ...station, tags };
}

function removeTag(station: IStation, tag: string | undefined): IStation {
	if (tag == null) {
		return station;
	}

	const lowercaseTag = tag.toLowerCase();
	if (!station.tags.some(t => t.toLowerCase() === lowercaseTag)) {
		return station;
	}

	const tags = station.tags.filter(t => t.toLowerCase() !== lowercaseTag);

	return { ...station, tags };
}

function addEndpoint(station: IStation, href: string | undefined) {
	if (href == null) {
		return station;
	}

	const endpoints = [...station.endpoints, { href }];

	return { ...station, endpoints };
}

function removeEndpoint(station: IStation, href: string | undefined) {
	if (href == null) {
		return station;
	}

	const endpoints = station.endpoints.filter(endpoint => endpoint.href !== href);

	return { ...station, endpoints };
}

function moveEndpoint(station: IStation, href: string | undefined, delta: number) {
	if (href == null) {
		return station;
	}

	const endpoints = station.endpoints.slice();
	const index = endpoints.findIndex(e => e.href === href);
	const endpoint = endpoints.splice(index, 1)[0];
	endpoints.splice(index + delta, 0, endpoint);

	return { ...station, endpoints };
}

export function createStationSlice() {
	/* eslint-disable sort-keys */
	return createSlice({
		initialState: { ...blankState, ...(typeof stationState !== "undefined" ? stationState : {}) },
		name: "station",
		reducers: {
			editStationTitle: (state, { payload }: PayloadAction<{ title: string }>) => {
				state.station.title = payload.title;
			},
			editStationWebsite: (state, { payload }: PayloadAction<{ url: string }>) => {
				state.station.links.website = payload.url;
			},
			addStationEndpoint: (state, { payload }: PayloadAction<{ href: string }>) => {
				state.station = addEndpoint(state.station, payload.href);
			},
			removeStationEndpoint: (state, { payload }: PayloadAction<{ href: string }>) => {
				state.station = removeEndpoint(state.station, payload.href);
			},
			moveStationEndpointUp: (state, { payload }: PayloadAction<{ href: string }>) => {
				state.station = moveEndpoint(state.station, payload.href, -1);
			},
			moveStationEndpointDown: (state, { payload }: PayloadAction<{ href: string }>) => {
				state.station = moveEndpoint(state.station, payload.href, +1);
			},
			addStationTag: (state, { payload }: PayloadAction<{ tag: string }>) => {
				state.station = addTag(state.station, payload.tag);
			},
			removeStationTag: (state, { payload }: PayloadAction<{ tag: string }>) => {
				state.station = removeTag(state.station, payload.tag);
			},
			stationRequestTriggered: state => {
				state.dirty = true;
			},
			stationRequestStarted: state => {
				state.operationFailed = false;
				state.operationInProgress = true;
			},
			stationRequestFailed: state => {
				state.operationFailed = true;
				state.operationInProgress = false;
			},
			stationRequestCompleted: (state, { payload }: PayloadAction<{ response: IStationResponse }>) => {
				if (payload.response.stations.length > 0) {
					const station = payload.response.stations[0];
					if (state.station.id == null && station.id != null) {
						window.location.href = editStationUrl(station);
					}

					state.dirty = false;
					state.operationInProgress = false;
					state.station = station;
				}
			}
		}
	});
	/* eslint-enable sort-keys */
}

export type StationActionCreators = ReturnType<typeof createStationSlice>["actions"];

export type StationReducer = ReturnType<typeof createStationSlice>["reducer"];

async function getErrorText(response: Response): Promise<{ title: string; message: string | null }> {
	const contentType = response.headers.get("Content-Type");
	let message = response.headers.get("X-ErrorMessage");
	const stackTraceBase64 = response.headers.get("X-Stacktrace-Base64");
	if (contentType?.startsWith("application/json") === true) {
		const error = await response.json() as { exceptionMessage?: string; message?: string };
		message = error.exceptionMessage ?? error.message ?? null;
	}

	if (stackTraceBase64) {
		console.error(atob(stackTraceBase64));
	}

	if (response.status >= 400 && response.status < 500) {
		return { message, title: "Client error" };
	}

	if (response.status >= 500 && response.status < 600) {
		return { message, title: "Server error" };
	}

	return { message, title: "Unknown error" };
}

function isEmpty(value: string) {
	return value.length === 0;
}

export function stationIsValid(station: IStation) {
	if (isEmpty(station.title) || isEmpty(station.links.website)) {
		return false;
	}

	if (station.tags.length === 0 || station.endpoints.length === 0) {
		return false;
	}

	return true;
}

function stationApiAction(actions: IActionCreators, method: (station: IStation) => Promise<Response>, successMessage: string, validate: boolean): AppThunk {
	return async (dispatch, getState) => {
		dispatch(actions.station.stationRequestTriggered());

		const state = getState();
		if (validate && !stationIsValid(state.station.station)) {
			return;
		}

		dispatch({ type: "StationRequestStarted" });

		try {
			const response = await method(state.station.station);
			if (response.ok) {
				const content = await response.json() as IStationResponse;
				dispatch(actions.station.stationRequestCompleted({ response: content }));
				Notify.success(successMessage);
			} else {
				const { title, message } = await getErrorText(response);
				Notify.error(title, message);
				dispatch(actions.station.stationRequestFailed());
			}
		} catch {
			Notify.error("Network error");
			dispatch(actions.station.stationRequestFailed());
		}
	};
}

export function createStation(actions: IActionCreators) {
	return stationApiAction(actions, station => StationApi.create(station), "Station created", true);
}

export function updateStation(actions: IActionCreators) {
	return stationApiAction(actions, station => StationApi.update(station), "Station updated", true);
}

export function deleteStation(actions: IActionCreators) {
	return stationApiAction(actions, station => StationApi.remove(station), "Station flagged as deleted", false);
}

export function restoreStation(actions: IActionCreators) {
	return stationApiAction(actions, station => StationApi.restore(station), "Station restored", false);
}
