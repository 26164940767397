import { createTopbar } from "Common/Topbar";

let progressCount = 0;
const topbar = createTopbar({
	barColors: [
		[0, "rgba(255, 119, 68, .7)"],
		[1, "rgba(255, 143, 0, .7)"]
	]
});

function enter() {
	progressCount++;
	topbar.show();
}

function exit() {
	progressCount--;

	if (progressCount === 0) {
		topbar.hide();
	}
}

function isCanvasSupported() {
	const elem = document.createElement("canvas");

	return !!elem.getContext("2d");
}

export async function progress<T>(promise: Promise<T>): Promise<T> {
	if (!isCanvasSupported()) {
		return promise;
	}

	enter();

	try {
		const result = await promise;
		exit();

		return result;
	} catch {
		exit();

		return promise;
	}
}
